<template>
	<div>
		<CCard>
			<CCardHeader>
				<h4 class="center-text">
					Historial de Autorizaciones
				</h4>
			</CCardHeader>
			<CCardBody>
				<div
					id="information"
					v-show="this.showContent === 'information'"
				>
					<CAlert v-if="showAlertError" color="danger">
						<h5 class="center-text">
							El cliente
							<b>{{ customerData.nombre_comercial }}</b> no posee
							una solicitud de widget, por lo cual no tiene
							historial en <b>InfoFeed</b>.
						</h5>
					</CAlert>
					<CRow>
						<CCol sm="12">
							<br />
							<p class="h6 justify-text">
								En este m&oacute;dulo podr&aacute;s consultar el
								historial de solicitudes de autorizaci&oacute;n
								de un cliente.
							</p>
							<p class="h6 justify-text">
								Para consultar el historial de un cliente
								ingresa en la siguiente casilla su
								<b>Nombre Comercial</b>. A medida que escribas
								el <b>Nombre Comercial</b>, se mostrar&aacute;n
								los nombres que coincidan con tu b&uacute;squeda
								y al <b>hacer clic</b> en alguno de ellos se
								seleccionar&aacute; autom&aacute;ticamente:
							</p>
						</CCol>
					</CRow>
					<customer-auto-complete @submit="submitCustomer" />
				</div>
				<div
					id="history-table"
					v-show="this.showContent === 'showTable'"
				>
					<CRow>
						<CCol sm="12">
							<br />
							<p class="h4 center-text">
								Historial de
								<b>{{ this.customerData.nombre_comercial }}</b>
							</p>
						</CCol>
					</CRow>
					<CRow>
						<CCol sm="12">
							<p class="h6 justify-text">
								Puedes usar el campo con el icono
								<i class="fas fa-search"></i> para filtrar los
								resultados.
							</p>
						</CCol>
					</CRow>
					<!-- buscador de la tabla -->
					<search-tables @search="doSearch" />
					<!-- tabla -->
					<CRow>
						<CCol sm="12">
							<p style="margin-bottom: 30px;">
								<span class="float-right"
									>Total:
									<CBadge shape="pill" color="info">
										{{
											this.dataForPager.totalRecords
										}}</CBadge
									>
								</span>
							</p>
							<b-table
								class="text-left"
								stacked="lg"
								striped
								outlined
								hover
								bordered
								:busy.sync="isBusy"
								:items="dataTable"
								:fields="fieldsTable"
								empty-text="No existe historial"
								ref="dataTable"
								show-empty
							>
								<template #empty="scope">
									<p class="h6 justify-text">
										{{ scope.emptyText }}
									</p>
								</template>
							</b-table>
						</CCol>
					</CRow>
					<pager
						v-if="this.dataForPager.totalRecords > 0"
						:data-pager="this.dataForPager"
						@changePage="changePage"
					/>
					<br />
					<CRow>
						<CCol sm="12">
							<CRow class="content-right">
								<CCol sm="12">
									<b-button
										@click="goBack"
										title="Cancelar"
										id="goBack"
										pill
										variant="warning"
										class="mr-1 text-white"
									>
										Regresar
									</b-button>
								</CCol>
							</CRow>
						</CCol>
					</CRow>
				</div>
			</CCardBody>
		</CCard>
	</div>
</template>

<script>
	import customerAutoComplete from '../../components/adminWidgets/customerAutoComplete.vue';
	import functionsForAdminWidgets from '../../functions/functionsForAdminWidgets';
	import { userProfiles } from '../../constants/profilesUsers';
	import Pager from '../../components/general/pager.vue';
	import SearchTables from '../../components/general/searchTables.vue';
	export default {
		components: { customerAutoComplete, Pager, SearchTables },
		name: 'historyAuths',
		title: 'Info Feed | Infoguia',
		data() {
			return {
				showContent: 'information',
				customerData: {},
				recordsPerPage: process.env.VUE_APP_RECORDS_PER_PAGE,
				currentPage: 1,
				page: null,
				totalRecords: 0,
				customerData: {},
				fieldsTable: [
					{
						key: 'date',
						label: 'Fecha del proceso',
					},
					{
						key: 'status',
						label: 'Estatus',
					},
					{
						key: 'user',
						label: 'Usuario/Cliente',
					},
					{
						key: 'description',
						label: 'Descripción',
					},
				],
				dataTable: [],
				isBusy: false,
				dataForPager: {
					recordsPerPage: process.env.VUE_APP_RECORDS_PER_PAGE,
					currentPage: 1,
					totalRecords: 0,
				},
				searchText: null,
				showAlertError: false,
			};
		},
		created() {},
		methods: {
			submitCustomer(result) {
				if (result !== undefined) {
					this.customerData = result;
					this.tableProvider();
				}
			},
			async tableProvider() {
				let promise = functionsForAdminWidgets.getHistoryDetails(
					this.customerData.idCustomers,
					this.recordsPerPage,
					this.page,
					this.searchText
				);
				this.isBusy = true;
				this.showAlertError = false;
				try {
					const response = await promise;
					const items = [];
					this.isBusy = false;
					this.showContent = 'showTable';
					this.dataForPager.totalRecords = response.total;
					this.dataForPager.recordsPerPage = response.per_page;
					this.dataForPager.currentPage = response.current_page;
					for (let index = 0; index < response.data.length; index++) {
						items[index] = {
							date: response.data[index].date,
							status: response.data[index].status.name,
							user:
								response.data[index].user.idUsers ===
								userProfiles.none
									? this.customerData.nombre_comercial
									: response.data[index].user.name,
							description: response.data[index].description,
						};
					}
					this.dataTable = items;
					return items;
				} catch (error) {
					this.showAlertError = true;
					console.log(error);
					return [];
				}
			},
			goBack() {
				this.showContent = 'information';
				this.dataTable = [];
				this.customerData = {};
				this.page = 1;
				this.currentPage = 1;
				this.recordsPerPage = process.env.VUE_APP_RECORDS_PER_PAGE;
			},
			changePage(page) {
				this.page = page;
				this.tableProvider();
				this.$refs.dataTable.refresh();
			},
			doSearch(value) {
				this.searchText = value;
				this.tableProvider();
				this.$refs.dataTable.refresh();
			},
		},
	};
</script>

<style></style>
